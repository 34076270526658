import { Component, OnInit, Input } from '@angular/core';
import { PageComponent } from 'src/app/models/page';

@Component({
  selector: 'app-what-we-do-desktop',
  templateUrl: './what-we-do-desktop.component.html',
  styleUrls: ['./what-we-do-desktop.component.less']
})
export class WhatWeDoDesktopComponent implements OnInit {

  @Input() pageComponent: PageComponent;

  IsOpeningLeft:boolean = false;
  IsOpeningRight:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges()
  {
    this.loadImages();
  }

  loadImages() {
    if(this.pageComponent)
    {
      let left = this.pageComponent.pageComponentAssets.find(x => x.altAttribute == "left")
      let right = this.pageComponent.pageComponentAssets.find(x => x.altAttribute == "right")
      let leftbw = this.pageComponent.pageComponentAssets.find(x => x.altAttribute == "left-bw")
      let rightbw = this.pageComponent.pageComponentAssets.find(x => x.altAttribute == "right-bw")

      document.documentElement.style.setProperty('--wwd-bg-l-url',`url("${left.filePath}")`);
      document.documentElement.style.setProperty('--wwd-bg-lbw-url',`url("${leftbw.filePath}")`);
      document.documentElement.style.setProperty('--wwd-bg-r-url',`url("${right.filePath}")`);
      document.documentElement.style.setProperty('--wwd-bg-rbw-url',`url("${rightbw.filePath}")`);

    }

  }

}
