<section id="what-we-do-l">
    <div class="square"></div>
    <a routerLink="/medical-device-distribution">
        <div class="wwd-image wwd-1">
            <img class="curve" src="../../../../../assets/images/what-we-do/row-1.png" />
            <div class="overlay"></div>
            <div class="title-box">
                <!--<div class="hover-container"></div>-->
                <h4>Medical Device<br />Distribution</h4>
                <div class="divider-line"></div>
                <div class="view">
                    <app-arrow-button [ButtonText]="'View'" [TextColor]="'#fff'" [FontSize]="'13px'"></app-arrow-button>
                </div>
            </div>
        </div>
    </a>
    <a routerLink="/specialities">
        <div class="wwd-image wwd-2">
            <img class="curve" src="../../../../../assets/images/what-we-do/row-2.png" />
            <div class="overlay"></div>
            <div class="title-box">
                <!--<div class="hover-container"></div>-->
                <h4>Medical Specialties</h4>
                <div class="divider-line"></div>
                <div class="view">
                    <app-arrow-button [ButtonText]="'View'" [TextColor]="'#fff'" [FontSize]="'13px'"></app-arrow-button>
                </div>
            </div>
        </div>
    </a>
    <a routerLink="/suppliers">
        <div class="wwd-image wwd-3">
            <img class="curve" src="../../../../../assets/images/what-we-do/row-3.png" />
            <div class="overlay"></div>
            <div class="title-box">
                <!--<div class="hover-container"></div>-->
                <h4>Suppliers</h4>
                <div class="divider-line"></div>
                <div class="view">
                    <app-arrow-button [ButtonText]="'View'" [TextColor]="'#fff'" [FontSize]="'13px'"></app-arrow-button>
                </div>
            </div>
        </div>
    </a>

</section>