import { Component, Input, OnInit } from '@angular/core';
import { PageComponent } from 'src/app/models/page';

@Component({
  selector: 'app-we-do-more-links-l',
  templateUrl: './we-do-more-links-l.component.html',
  styleUrls: ['./we-do-more-links-l.component.less']
})
export class WeDoMoreLinksLComponent implements OnInit {

  @Input() pageComponent: PageComponent;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges()
  {
    this.loadImages();
  }

  loadImages() {
    if(this.pageComponent)
    {
      let one = this.pageComponent.pageComponentAssets.find(x => x.altAttribute == "wdm-1")
      let two = this.pageComponent.pageComponentAssets.find(x => x.altAttribute == "wdm-2")
      let three = this.pageComponent.pageComponentAssets.find(x => x.altAttribute == "wdm-3")
//filePath
      document.documentElement.style.setProperty('--wdm-bg-1-url',`url("${one.filePath}")`);
      document.documentElement.style.setProperty('--wdm-bg-2-url',`url("${two.filePath}")`);
      document.documentElement.style.setProperty('--wdm-bg-3-url',`url("${three.filePath}")`);

    }

  }
}
