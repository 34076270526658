import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { Page, PageComponent } from 'src/app/models/page';
import { PageService } from 'src/app/service/page.service';

@Component({
  selector: 'app-we-do-more-value-chain',
  templateUrl: './we-do-more-value-chain.component.html',
  styleUrls: ['./we-do-more-value-chain.component.less']
})
export class WeDoMoreValueChainComponent implements OnInit {
  pageCode: string = "WDMP001";
  pageResource: Page;
  pageMainComponent: PageComponent;
  
  safeSrc: SafeResourceUrl;

  constructor(private _service: PageService, private titleService: Title, private sanitizer: DomSanitizer) {
    this.titleService.setTitle("Value Chain | Uniphar Medtech");
   }

  ngOnInit(): void {
    this.loadReferenceData();
  }

  loadReferenceData(){
    this._service.getPageByCode(this.pageCode).subscribe(result => {
      if(result)
      {
        this.pageResource = result as Page;
        this.pageMainComponent = result.pageComponents.find(x => x.id == 41);
        this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.pageMainComponent.statContent);
        //console.log(this.pageResource);
      }
    });

  }
}
