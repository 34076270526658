<div class="padding-container">
  <div class="atf atf-init we-do-more-intro-ctr">
    <div class="row full-height">
      <div class="col-12 col-md-5 full-height left" id="left">
        <h1>{{pageMainComponent?.sectionTitle}}</h1>
          <p [innerHTML]="pageMainComponent?.sectionContent">
          </p>
        <!--<a routerLink="">
          <ul class="attention">

            <li>
              <app-attention-bar></app-attention-bar>
            </li>
            <li>
              <p class="nowrap">Uniphar Medtech Value Chain</p>
            </li>
          </ul>
        </a>-->
      </div>
      <div class="col-12 col-md-7 full-height" id="right">
        <div class="full-height">
          <div class="row full-height">
            <div class="col full-height" style="padding-left: 0px;">
              <app-we-do-more-links-l [pageComponent]="pageMainComponent"></app-we-do-more-links-l>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-breadcrumb
  [Links]="'<a class=\'bold\'  href=\'\\we-do-more-intro\'>We Do More</a>&nbsp;&nbsp;|&nbsp;&nbsp;Overview'">
</app-breadcrumb>