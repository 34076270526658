import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-home-menu',
    templateUrl: './home-menu.component.html',
    styleUrls: ['./home-menu.component.less']
})
export class HomeMenuComponent implements OnInit {
    ActiveIndex: number = 99;
    constructor(public router: Router) { 
        /*debugger;
        if (
            this.router.url === "/" || this.router.url === "/history" || this.router.url === "/vision" || 
            this.router.url === "/social" || this.router.url === "/culture"
        ) {
            this.ActiveIndex = 0;
        } else if (
            this.router.url === "/what-we-do"
        ) {
            this.ActiveIndex = 1;
        }*/
    }

    ngOnInit(): void {
        
    }

    showSubMenu(obj: HTMLUListElement) {
        obj.classList.add("shown")
    }

    clearSubMenu(obj: HTMLUListElement) {
        obj.classList.remove("shown")
    }

}
