import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { PageComponent, PageComponentAsset } from 'src/app/models/page';

@Component({
  selector: 'app-mdd-top',
  templateUrl: './mdd-top.component.html',
  styleUrls: ['./mdd-top.component.less']
})
export class MddTopComponent implements OnInit {

  @Input() pageComponent: PageComponent;
  pageTopAsset: PageComponentAsset;
  imageToggleEnabled:boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.loadResources();
    window.setTimeout(() => {
      document.getElementById("slide").classList.remove("open");
      document.getElementById("copy").classList.remove("hidden");
      this.imageToggleEnabled = true;
    }, 5000);
  }
  ngOnChanges() {
    this.loadResources();
  }

  hideImage() {
    if (this.imageToggleEnabled) document.getElementById("copy").classList.add("hidden");
  }

  showImage() {
    if (this.imageToggleEnabled) document.getElementById("copy").classList.remove("hidden");
  }

  loadResources()
  {
      if(this.pageComponent)
      {
        let image = this.pageComponent.pageComponentAssets.find(x => x.altAttribute == "modal-1")
        this.pageTopAsset = this.pageComponent.pageComponentAssets.find(x => x.altAttribute == "mdd-top")
        if(image)
        {
          document.documentElement.style.setProperty('--mdd-bg-top-url',`url("${image.filePath}") no-repeat center center`);
        }

      }

  }
}
