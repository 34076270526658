import { Component, OnInit, Input } from '@angular/core';
import { PageComponent, PageComponentAsset } from 'src/app/models/page';

@Component({
  selector: 'app-mdd-mobile',
  templateUrl: './mdd-mobile.component.html',
  styleUrls: ['./mdd-mobile.component.less']
})
export class MddMobileComponent implements OnInit {
  @Input() pageTopComponent: PageComponent;
  @Input() pageBottomComponent: PageComponent;
  @Input() pageTopComponentMobile: PageComponent;

  pageTopAsset: PageComponentAsset;

  constructor() { }

  ngOnInit(): void {
    this.loadResources();
  }

  ngOnChanges() {
    this.loadResources();
  }

  loadResources()
  {
    if(this.pageBottomComponent)
      {
        let image = this.pageBottomComponent.pageComponentAssets.find(x => x.altAttribute == "mdd-bottom")
        if(image)
        {
          document.documentElement.style.setProperty('--mdd-m-bg-bottom-url',`url("${image.filePath}") no-repeat center center`);
        }

      }

      if(this.pageTopComponent)
      {
        let image = this.pageTopComponent.pageComponentAssets.find(x => x.altAttribute == "modal-1")
        this.pageTopAsset = this.pageTopComponent.pageComponentAssets.find(x => x.altAttribute == "mdd-top")
        if(image)
        {
          document.documentElement.style.setProperty('--mdd-m-bg-top-url',`url("${image.filePath}") no-repeat center center`);
        }

      }

  }

}
