import { Component, OnInit } from "@angular/core";
import { Page } from 'src/app/models/page';
import { PageService } from 'src/app/service/page.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: "app-social",
  templateUrl: "./social.component.html",
  styleUrls: ["./social.component.less"],
})
export class SocialComponent implements OnInit {

  pageCode: string = "SC001";
  pageResource: Page;

  currentState: number = 0;
  totalStates: number = 5;
  timer;
  constructor(private _service: PageService, private titleService: Title) {
    this.titleService.setTitle("Our Social Contributions | Uniphar Medtech");
  }

  ngOnInit(): void {
    this.startTimer();
    this.loadReferenceData();
  }

  SetState(state: number) {
    this.stopTimer();
    this.currentState = state;
    this.startTimer();
  }

  startTimer(): void {
    this.timer = window.setInterval(() => {
      this.currentState = this.getNextStateUp();
    }, 8000);
  }

  stopTimer(): void {
    window.clearInterval(this.timer);
  }

  getNextStateUp(): number {
    var nextState: number;
    nextState = this.currentState + 1;
    if (nextState == this.totalStates) nextState = 0;
    return nextState;
  }

  getNextStateDown(): number {
    var nextState: number;
    nextState = this.currentState - 1;
    if (nextState < 0) nextState = this.totalStates-1;
    return nextState;
  }

  onSwipeLeft(ev) {
    this.stopTimer();
    this.currentState = this.getNextStateUp();
    this.startTimer();
  }

  onSwipeRight(ev) {
    this.stopTimer();
    this.currentState = this.getNextStateDown();
    this.startTimer();
  }

  mobileStateChanged(newState) {
    this.stopTimer();
    this.currentState = newState;
    this.startTimer();
  }

  loadReferenceData(){
    this._service.getPageByCode(this.pageCode).subscribe(result => {
      if(result)
      {
          this.pageResource = result as Page;
          console.log(this.pageResource);
      }
    });

  }


}
