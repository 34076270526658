import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { PageComponent } from 'src/app/models/page';

@Component({
  selector: 'app-copy-ticker-mobile',
  templateUrl: './copy-ticker-mobile.component.html',
  styleUrls: ['./copy-ticker-mobile.component.less']
})
export class CopyTickerMobileComponent implements OnInit {
  
  @Input() state:number = 0;

  @Input() social1: PageComponent;
  @Input() social2: PageComponent;
  @Input() social3: PageComponent;
  @Input() social4: PageComponent;
  @Input() social5: PageComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
