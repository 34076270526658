<ul>
  <li class="page">
    <div class="clickarea" (click)="SetState(0)">
      <div class="indicator" [class.active]="state===0"></div>
    </div>
  </li>
  <li class="page">
    <div class="clickarea" (click)="SetState(1)">
      <div class="indicator" [class.active]="state===1"></div>
    </div>
  </li>
  <li class="page">
    <div class="clickarea" (click)="SetState(2)">
      <div class="indicator" [class.active]="state===2"></div>
    </div>
  </li>
  <li class="page">
    <div class="clickarea" (click)="SetState(3)">
      <div class="indicator" [class.active]="state===3"></div>
    </div>
  </li>
  <li class="page">
    <div class="clickarea" (click)="SetState(4)">
      <div class="indicator" [class.active]="state===4"></div>
    </div>
  </li>
</ul>
