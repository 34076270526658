import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { CarouselModule } from 'ngx-owl-carousel-o';
import { SharedModuleRoutingModule } from './shared-module-routing.module';

import { SharedModuleComponent } from './shared-module.component';
import { ArrowButtonComponent } from './arrow-button/arrow-button.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AttentionBarComponent } from './attention-bar/attention-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ArrowOnlyComponent } from './arrow-only/arrow-only.component';




@NgModule({
    declarations: [
        SharedModuleComponent,
        ArrowButtonComponent,
        BreadcrumbComponent,
        AttentionBarComponent,
        ArrowOnlyComponent
    ],
    imports: [
        CommonModule,
        SharedModuleRoutingModule,
        FontAwesomeModule,
        //CarouselModule
    ],
    exports: [
        ArrowButtonComponent,
        BreadcrumbComponent,
        AttentionBarComponent,
        ArrowOnlyComponent,
    ]
})
export class SharedModuleModule { }
