<ul>
    <a href="/">
        <li class="title">Home</li>
    </a>
    <a href="/history">
        <li>History</li>
    </a>

    <a href="/vision">
        <li>Vision, Mission</li>
    </a>
    <a href="/social">
        <li>Our Social Contribution</li>
    </a>
    <a href="/culture">
        <li>Our Culture</li>
    </a>
    <li class="divider"></li>
    <a href="/what-we-do">
        <li class="title">What We Do</li>
    </a>
    <a href="/medical-device-distribution">
        <li>Medical Device Distribution</li>
    </a>
    <a href="/specialities">
        <li>Specialities</li>
    </a>
    <a href="/suppliers">
        <li>Suppliers</li>
    </a>
    <li class="divider"></li>
    <a href="/we-do-more-intro">
        <li class="title">We Do More</li>
    </a>
    <a href="/value-chain">
        <li>Value Chain </li>
    </a>
    <a href="/ourpeople">
        <li>Sales Structure</li>
    </a>
    <a href="/we-do-more">
        <li>Support Division</li>
    </a>
    <li class="divider"></li>
    <a href="/investors">
        <li class="title">Investors</li>
    </a>
    <a href="/investors">
        <li>Overview</li>
    </a>
    <a href="/investors/acquisitions">
        <li>Acquisitions</li>
    </a>
    <a target="_blank" href="https://www.uniphar.ie/">
        <li>Uniphar</li>
    </a>

    <li class="divider"></li>
    <a href="/careers">
        <li class="title">Careers</li>
    </a>
    <a href="/careers/testimonials">
        <li>Testimonials</li>
    </a>
    <!--<a href="/careers/positions">
        <li>Available Positions</li>
    </a>-->
    <a target="_blank" href="https://apply.workable.com/uniphar-medtech/?lng=en">
        <li>Available Positions</li>
    </a>
    <li class="divider"></li>
    <a href="/ourbusinesses">
        <li class="title">Our Businesses</li>
    </a>
    <a href="/ourbusinesses">
        <li>Overview</li>
    </a>
    <li class="divider"></li>
    <div class="">
        <a href="/news">
            <li class="title">News</li>
        </a>
        <a href="/news">
            <li>Latest Articles</li>
        </a>

    </div>
    <li class="divider"></li>
    <a href="/contact">
        <li class="title">Contact</li>
    </a>
</ul>