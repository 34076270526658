<section id="we-do-more-l">
    <div class="square"></div>
    <a routerLink="/value-chain">
        <div class="wdm-image wdm-1">
            <img class="curve" src="../../../../../assets/images/what-we-do/row-1.png" />
            <div class="overlay"></div>
            <div class="title-box">
                <h4>Uniphar Medtech<br />Value Chain</h4>
                <div class="divider-line"></div>
                <div class="view">
                    <app-arrow-button [ButtonText]="'View'" [TextColor]="'#fff'" [FontSize]="'13px'"></app-arrow-button>
                </div>
            </div>
        </div>
    </a>
    <a routerLink="/ourpeople">
        <div class="wdm-image wdm-2">
            <img class="curve" src="../../../../../assets/images/what-we-do/row-2.png" />
            <div class="overlay"></div>
            <div class="title-box">
                <h4>Uniphar Medtech<br />Sales Structure</h4>
                <div class="divider-line"></div>
                <div class="view">
                    <app-arrow-button [ButtonText]="'View'" [TextColor]="'#fff'" [FontSize]="'13px'"></app-arrow-button>
                </div>
            </div>
        </div>
    </a>
    <a routerLink="/we-do-more">
        <div class="wdm-image wdm-3">
            <img class="curve" src="../../../../../assets/images/what-we-do/row-3.png" />
            <div class="overlay"></div>
            <div class="title-box">
                <h4>Uniphar Medtech<br />Support Divisions</h4>
                <div class="divider-line"></div>
                <div class="view">
                    <app-arrow-button [ButtonText]="'View'" [TextColor]="'#fff'" [FontSize]="'13px'"></app-arrow-button>
                </div>
            </div>
        </div>
    </a>

</section>

