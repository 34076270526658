import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Page, PageComponent, PageComponentAsset } from 'src/app/models/page';
import { PageService } from 'src/app/service/page.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-culture',
  templateUrl: './culture.component.html',
  styleUrls: ['./culture.component.less']
})
export class CultureComponent implements OnInit {

  pageCode: string = "CLTR001";
  pageResource: Page;
  pageMainComponent: PageComponent;
  pageSubComponent: PageComponent;
  subAssetFlow1: PageComponentAsset;
  subAssetFlow2: PageComponentAsset;
  subAssetFlow3: PageComponentAsset;
  subAssetFlow4: PageComponentAsset;

  constructor(private _service: PageService, private titleService: Title) {
    this.titleService.setTitle("Our Culture | Uniphar Medtech");
  }

  ngOnInit(): void {
    this.loadReferenceData();
  }

  loadReferenceData(){
    this._service.getPageByCode(this.pageCode).subscribe(result => {
      if(result)
      {
          this.pageResource = result as Page;
          result.pageComponents.forEach(element => {
            if(element.id == 24)
            {
              this.pageMainComponent = element;
              let cultureImage1 = element.pageComponentAssets.find(x => x.altAttribute == "culture-1");
              let cultureImage2 = element.pageComponentAssets.find(x => x.altAttribute == "culture-2");
              if(cultureImage1 && cultureImage2)
              {
                document.documentElement.style.setProperty('--culture-bg-1-url',`url("${cultureImage1.filePath}")  no-repeat left center`);
                document.documentElement.style.setProperty('--culture-bg-2-url',`url("${cultureImage2.filePath}")  no-repeat right -300px center`);
              }
            }
            else if(element.id == 25 && element.pageComponentAssets)
            {
              this.pageSubComponent = element;
              element.pageComponentAssets.forEach(x => {
                if(x.altAttribute == "doctors-1")
                {
                  this.subAssetFlow1 = x;
                }
                else if(x.altAttribute == "chat")
                {
                  this.subAssetFlow2 = x;
                }
                else if(x.altAttribute == "doctors-2")
                {
                  this.subAssetFlow3 = x;
                }
                else if(x.altAttribute == "lady")
                {
                  this.subAssetFlow4 = x;
                }
              });
            }

          });
          console.log(this.pageResource);
      }
    });

  }

}
