<section class="copy socialcopy">
  <div class="one sub-copy" [class.showcopy]="state===0" [innerHTML]="social1?.sectionContent">
  </div>
  <div class="two sub-copy" [class.showcopy]="state===1" [innerHTML]="social2?.sectionContent">
  </div>
  <div class="three sub-copy" [class.showcopy]="state===2" [innerHTML]="social3?.sectionContent">
  </div>
  <div class="four sub-copy" [class.showcopy]="state===3" [innerHTML]="social4?.sectionContent">
  </div>
  <div class="five sub-copy" [class.showcopy]="state===4" [innerHTML]="social5?.sectionContent">
  </div>
</section>
