<div class="ctr">
  <div class="container-fluid atf atf-init">
    <div class="row full-height">
      <div class="col-lg-12 col-xl-6 left full-height">
        <div class="speciality-container">
          <app-inline-breadcrumb [Links]="'<a class=\'bold\'  href=\'\\what-we-do\'>What We Do</a>&nbsp;&nbsp;|&nbsp;&nbsp;Specialities'"></app-inline-breadcrumb>
          <h2>Speciality</h2>
          <div class="row">
              <div class="col-6" *ngFor="let item of getDataListCollection()"><p (mouseover)="SetSpecialityV2(item)" (click)="onView(item)">{{item.description}}</p></div>
          </div>
          <!--<app-arrow-button [Target]="'/we-do-more'" [ButtonText]="'We do More'" [TextColor]="'white'"></app-arrow-button>-->
          <div class="next-step">
            <a routerLink="/we-do-more">
              <ul class="attention">
                <li>
                  <app-attention-bar></app-attention-bar>
                </li>
                <li>
                  <p>We Do More</p>
                </li>
              </ul>
            </a>
          </div>
        </div>
      </div>
      <div class="d-none d-xl-block col-xl-6 right full-height">
        <app-speciality-summary [Speciality]="currentContent"></app-speciality-summary>
      </div>
    </div>
  </div>
</div>
