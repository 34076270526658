import { BUSINESSES } from "./enums";
import { baseApiUrl } from "mapbox-gl";
import { build$ } from "protractor/built/element";

export class Speciality {
  Index: number;
  Description: string;
  ProfilePicture: string;
  WriteUp?: string;
  public Businesses?: BUSINESSES[];
  KeySuppliers?: string[];
  Tag: string;
}

export class ContentGroup {
  id: number;
  contentGroupName: string;
  contentGroupDescription: string;
  contentGroupCode: boolean;

  contents: ContentItem[];

  updatedDate: any;
  createdDate: any;
}

export class ContentItem {
  id: number;
  contentGroupId: number;

  contentName: string;
  description: string;
  contentImage: string;
  contentAlt: string;
  contentUrl: string;
  seoTags: string;
  seoWriteUp: string;

  index: number;
  Index: string;
  writeUp : string;
  businesses : string;
  keySuppliers: string;
  htmlStats : string;
  htmlWriteUp : string;
  updatedDate: any;
  createdDate: any;

  sliderIndex:number =0;
}



export const SPECIALITIES:Speciality[] = [
  {
    Index: 0,
    Description: "Ambulatory Aids",
    ProfilePicture: "ambulatory.jpg",
    WriteUp:
      "Uniphar Medtech is partnered with the world’s leading manufacturers including the Sunrise Medical (Coopers), The Helping Hand Company and Herdegen. Our portfolio includes crutches, walking frames, sticks and extends to aids for daily living such as raised toilet sets, bath seats and dressing aids.",
    Businesses: [BUSINESSES.MED],
    KeySuppliers: ["Helping Hand Community", "Sunrise Medical", "Herdegen"],
    Tag: "ambulatory-aids",
  },
  {
    Index: 1,
    Description: "Managed Equipment Service",
    ProfilePicture: "managed-equipment.jpg",
    WriteUp:
      "MES manages all hospital equipment concerns such as ownership, provision, purchase, installation and commissioning, user training, asset management, maintenance and ongoing replacement. It is a vendor neutral offering, acting as a local facilitator between Manufacturers and Healthcare Provider ensuring a flexible programme, predictable replacement plan, risk facilitation and cost efficiencies through managed monthly payments.",
    Businesses: [BUSINESSES.Cardiac],
    Tag: "managed-equipment-service",
  },
  {
    Index: 2,
    Description: "Cardiovascular",
    ProfilePicture: "cardiovascular.jpg",
    WriteUp:
      "We offer the latest technology products in the PCI and chronic total occlusion areas. Our portfolio includes stents, wires, catheters, hemostasis valves, introducers and catheter balloons. ",
    Businesses: [
      BUSINESSES.Synapse,
      BUSINESSES.MacroMed,
      BUSINESSES.AngioCare,
      BUSINESSES.EPS,
      BUSINESSES.M3,
    ],
    KeySuppliers: [
      "Andratec",
      "Asahi Intecc",
      "Boston Scientific",
      "Cardionovum",
      "CeloNova",
      "OrbusNeich",
      "Shockwave",
      "Vascular Solutions",
    ],
    Tag: "cardiovascular",
  },
  {
    Index: 3,
    Description: "Maxillofacial",
    ProfilePicture: "maxillofacial.jpg",
    WriteUp:
      "Covering trauma, head and neck cancer and osteotomies, we provide products such as micro Instrumentation, motor/facial nerve stimulators, headlights, 3D orbital floor plates and custom mandible plates including cutting guides.",
    Businesses: [BUSINESSES.Tekno],
    KeySuppliers: ["Stryker"],
    Tag: "maxillofacial",
  },
  {
    Index: 4,
    Description: "Cardio-Thoracic and Vascular Solutions",
    ProfilePicture: "cardio-thoracic.jpg",
    WriteUp:
      "We cater for procedures such as cardiac, aortic and peripheral vascular grafts, and offer thoracic chest drainage systems and chest access kits. ",
    Businesses: [BUSINESSES.MED],
    KeySuppliers: [
      "Chemence Derma+Flex",
      "ClariVein (Vascular Insights)",
      "DuraGraft",
      "Geko/Firefly Sports Recovery",
      "Maquet Getinge",
      "Teleflex",
    ],
    Tag: "cardio-thoracic",
  },
  {
    Index: 5,
    Description: "Microscopes",
    ProfilePicture: "microscopes.jpg",
    WriteUp:
      "As the exclusive distributor for Zeiss in the Island of Ireland, Uniphar Medtech has multi-speciality teams with expertise across the full range of microscopes in neuro, ophthalmics and ENT.",
    Businesses: [BUSINESSES.MED, BUSINESSES.Tekno],
    KeySuppliers: ["Zeiss"],
    Tag: "microscopes",
  },
  {
    Index: 6,
    Description: "Clinical Engineering",
    ProfilePicture: "clinical-engineering.jpg",
    WriteUp:
      "Our Clinical Engineering team provide technical support from project inception, installation, post go-live preventative maintenance and upgrades to end of life disposal, ensuring customers meet all their compliance requirements at every point in their equipment’s life cycle.",
    Businesses: [BUSINESSES.Cardiac],
    KeySuppliers: [],
    Tag: "clinical-engineering",
  },
  {
    Index: 7,
    Description: "Neurosurgery",
    ProfilePicture: "neuro.jpg",
    WriteUp:
      "Our neurosurgery division supplies a wide range of medical equipment, software and consumables for theatre use with specialities including ultrasonic aspiration, electrosurgery and power tools. Our portfolio includes spinal implants, spinal cord stimulation, biologics, navigation and neuromonitoring devices. Uniphar Medtech also provides coils, balloons, stents, distal access catheters, distal access catheters, stentrievers, liquid embolics and other types of catheters. ",
    Businesses: [
      BUSINESSES.Tekno,
      BUSINESSES.AngioCare,
      BUSINESSES.EPS,
      BUSINESSES.M3,
    ],
    KeySuppliers: [
      "Nico",
      "Stryker",
      "Asahi Intecc",
      "MicroVention",
      "Symmetry Surgical",
    ],
    Tag: "neurosurgery",
  },
  {
    Index: 8,
    Description: "Critical Care and Infection Prevention",
    ProfilePicture: "critical.jpg",
    WriteUp:
      "Our CCIP division has an ever-expanding portfolio to meet the demands of current healthcare needs. Our portfolio consists UV-C Robots, latest technology air quality control, surgical masks, drapes and gowns, non-contact thermometers, automated shoe covering technology, custom procedural packs, small and large instrument and lens repair, suction liners, patient positioning and temperature management.",
    Businesses: [
      BUSINESSES.Cardiac,
      BUSINESSES.MED,
      BUSINESSES.Tekno,
      BUSINESSES.Synapse,
      BUSINESSES.MacroMed,
      BUSINESSES.AngioCare,
      BUSINESSES.EPS,
      BUSINESSES.M3,
    ],
    KeySuppliers: [
      "Air in Space",
      "BedAir Medical",
      "Kolmi-Hopen",
      "PureKeys",
      "Quen",
      "Radic8",
      "Steripro",
      "SurfaceSkins",
      "TriTemp",
      "Vacsax",
    ],
    Tag: "critical-care-and-infection-prevention",
  },
  {
    Index: 9,
    Description: "Non Surgical Plastics",
    ProfilePicture: "non-surgical-plastics.jpg",
    WriteUp:
      "Our expertise spans a wide range of areas including phototherapy, radiofrequency, skin analysis, micro needling, cosmeceutical skin care, and much more.",
    Businesses: [BUSINESSES.Tekno],
    KeySuppliers: [
      "Aesclepion",
      "Cosmedix",
      "Dermaceutics",
      "Dermalux",
      "EndyMed",
      "Heliocare",
      "Möller Medical",
      "Rejuvapen",
    ],
    Tag: "non-surgical-plastics",
  },
  {
    Index: 10,
    Description: "Diagnostic Imaging",
    ProfilePicture: "diagnostics-imaging.jpg",
    WriteUp:
      "The Diagnostic Imaging Division provides the highest level of customer engagement through market leading innovative technologies from GE, Ulrich Medical and Calmed. Through our highly skilled and medically trained personnel, we provide products such as: Contrast Media for CT, MRI and Cardiology, CO2 Insufflators, Syringeless Contrast Media Injectors (CT and MRI), Molecular Imaging Portfolio.",
    Businesses: [BUSINESSES.Synapse],
    KeySuppliers: ["Calmed", "GE", "Ulrich"],
    Tag: "diagnostic-imaging",
  },
  {
    Index: 11,
    Description: "Opthalmics",
    ProfilePicture: "opthalmics.jpg",
    WriteUp:
      "We boast a comprehensive portfolio of ophthalmic, surgical and clinical devices, which encompass a broad spectrum of products within the cataract, vitro retinal, glaucoma, oculoplastic and cornea areas. We represent global brand leaders including Carl Zeiss, Visunex, Meditec, Dorc, Iridex, Malosa, Optos, I-Care, Katena and Ophtec.",
    Businesses: [BUSINESSES.MED],
    KeySuppliers: [
      "Carl Zeiss",
      "Dorc",
      "I-Care",
      "Iridex",
      "Katena",
      "Malosa",
      "Meditec",
      "Ophtec",
      "Optos",
      "Visunex",
    ],
    Tag: "opthalmics",
  },
  {
    Index: 12,
    Description: "Ear, Nose and Throat (ENT)",
    ProfilePicture: "ent.jpg",
    WriteUp:
      "The ENT division have long standing relationships with leading suppliers. Our products include: Surgical Microscopes, Surgical Lasers, Nerve Monitoring Systems, Surgical Navigation, Specialised Instrumentation, Single-use Instrumentation, Surgical Illumination, Prostheses and more. We are one of the only third party companies in Europe to hold a fully accredited service license agreement with Stryker and Zeiss ensuring the best service and turnaround time nationwide.",
    Businesses: [BUSINESSES.Tekno],
    KeySuppliers: ["Devicor", "Scopis", "Stryker", "Exmoor", "Machida", "Kurz"],
    Tag: "ear-nose-throat",
  },
  {
    Index: 13,
    Description: "Orthopaedics",
    ProfilePicture: "ortho.jpg",
    WriteUp:
      "Our orthopaedics portfolio spans a broad spectrum of products to include Stryker Joint Replacement. Stryker Trauma & Extremeties, Stryker Spine, Stryker Craniomaxillafacial,  Stryker Sports medicine, Stryker Foot and Ankle,  Stryker Medical and Surgical Equipment, allowing us to deliver cutting edge technology for both patient and practitioner.",
    Businesses: [BUSINESSES.Tekno],
    KeySuppliers: ["Stryker"],
    Tag: "orthpaedics",
  },
  {
    Index: 14,
    Description: "Endoscopy",
    ProfilePicture: "endoscopy.jpg",
    WriteUp:
      "Our extensive portfolio of endoscopy products includes state-of-the-art camera systems, light sources, insufflators, scopes, and data management systems. The Stryker Precision IE Laparoscope is Stryker’s latest generation of premier Laparoscopes and increases the capabilities of high definition surgical video systems in operating rooms. The Stryker 1588 AIM Platform consists of five imaging modes including IRIS, ENV, Clarity, Desaturation and DRE. Our core focus is to provide laparoscopic, upper GI and colorectal surgical devices.",
    Businesses: [BUSINESSES.Cardiac, BUSINESSES.MED, BUSINESSES.Tekno],
    KeySuppliers: ["Stryker", "Olympus"],
    Tag: "endoscopy",
  },
  {
    Index: 15,
    Description: "Robotics and Navigation",
    ProfilePicture: "robotics.jpg",
    WriteUp:
      "The CorPath GRX Vascular Robotic System provides value for physicians, staff, and patients during interventional procedures where precise device and stent positioning is a top priority. ",
    Businesses: [BUSINESSES.Tekno, BUSINESSES.EPS],
    KeySuppliers: ["Stryker", "Siemens"],
    Tag: "robotics-and-navigation",
  },
  {
    Index: 16,
    Description: "Gastroenterology",
    ProfilePicture: "gastro.jpg",
    WriteUp:
      "The MiroCam® capsule captures images of the GI tract, which are sent via Human Body Communication to sensor pads which are affixed to the patient body. The sensor pads are connected to the receiver by the data cables. The image data is stored on the receiver for the duration of the patient procedure. Following upload, the physician (Gastroenterologist) reviews the patient image data for suspected abnormalities of the small bowel. ",
    Businesses: [BUSINESSES.AngioCare],
    KeySuppliers: ["Intromedic"],
    Tag: "gastroenterology",
  },
  {
    Index: 17,
    Description: "Spine and Interventional Pain",
    ProfilePicture: "spine.jpg",
    WriteUp:
      "Our spine and interventional pain portfolio includes differentiated implants designed to individually address trauma, degenerative, and oncology indications. Products include bone substitutes, screw rod systems, vertebral body replacements, intraspinal equipment and anterior plates.",
    Businesses: [BUSINESSES.MED, BUSINESSES.Tekno],
    KeySuppliers: ["Zeiss"],
    Tag: "spine-and-interventional-pain",
  },
  {
    Index: 18,
    Description: "Healthcare Informatics",
    ProfilePicture: "healthcare-informatics.jpg",
    WriteUp:
      "We supply innovative healthcare solutions across all areas of the IT infrastructure to the Hospital and Pre-Hospital marketplace. Our clinical IT team have interfaced over 30 medical devices from over 25 different manufacturers to Clinical Informatics systems. We have key installs and partners in Critical Care Clinical Information Systems, Mobile Healthcare Platforms. System and Device Integration, Patient Monitoring & Telemetry Systems, Electronic Rostering and Time & Attendance Solutions, Pharmacy Information Systems, Theatre Management Systems and Cardiology Information Systems",
    Businesses: [BUSINESSES.Cardiac],
    KeySuppliers: ["Nervecentre", "Olympus Keymed", "Philips"],
    Tag: "healthcare-informatics",
  },
  {
    Index: 19,
    Description: "Surgical Powertools and Instrumentation",
    ProfilePicture: "powertools.jpg",
    WriteUp:
      "Our power tool portfolio includes Strykers system 8, system 7, powered instruments and accessories used to cut, shape, fixate, and dissect bone as well as to fragment, emulsify and aspirate soft tissue in all types of procedures, from reconstructive, sports med and extremities, to the most complex in neurosurgery, spine and ENT.",
    Businesses: [BUSINESSES.Cardiac, BUSINESSES.Tekno],
    KeySuppliers: ["Scanlan", "Stryker", "Tekno Medical"],
    Tag: "surgical-powertools-and-instrumentation",
  },
  {
    Index: 20,
    Description: "Interventional Cardiology",
    ProfilePicture: "int-cardio.jpg",
    WriteUp:
      "We offer products such as percutaneous coronary intervention (PCI) to include: drug eluting stents, percutaneous transcatheter, coronary balloons, drug eluting balloons, guidewires, structural heart devices, point of care, vessel closure, FFR/IVUS imaging, electro physiology sheaths and pacemakers. ",
    Businesses: [
      BUSINESSES.Synapse,
      BUSINESSES.MacroMed,
      BUSINESSES.AngioCare,
      BUSINESSES.EPS,
      BUSINESSES.M3,
    ],
    KeySuppliers: [
      "Andramed",
      "Asahi Intecc",
      "Balton",
      "BBraun",
      "Bentley",
      "Boston Scientific",
      "Cadionovuum",
      "Orbus Neich",
      "Occlutech",
      "Teleflex",
      "Volcano",
      "Wellinq",
    ],
    Tag: "interventional-cardiology",
  },
  {
    Index: 21,
    Description: "Theatre Builds and Installations",
    ProfilePicture: "theatre-builds.jpg",
    WriteUp:
      "Through established partnerships with Stryker, Haelvoet, ACIME and Winncare, we provide a full range of theatre equipment including stretchers, transport chairs and medical beds for all departments including emergency departments, radiology, MRI and day care. ",
    Businesses: [BUSINESSES.Tekno],
    KeySuppliers: ["ACIME", "Haelvoet", "Stryker", "Winncare"],
    Tag: "theatre-builds-and-installations",
  },
  {
    Index: 22,
    Description: "Interventional Endovascular",
    ProfilePicture: "int-endo.jpg",
    WriteUp:
      "Our portfolio includes peripheral stents systems, drug eluting balloons, PTA balloons, guidewires, microcatheters, an embolisation portfolio, a vascular access portfolio, visco fluid, vertebroplasty, EVLT laser consoles, interventional oncology ablation systems and wires.",
    Businesses: [
      BUSINESSES.Synapse,
      BUSINESSES.MacroMed,
      BUSINESSES.AngioCare,
      BUSINESSES.EPS,
      BUSINESSES.M3,
    ],
    KeySuppliers: [
      "ALN Vena Cava Filter",
      "AndraStent®",
      "AngioDynamics",
      "Asahi Intecc",
      "Bentley",
      "Cadionovuum",
      "Hilzo Nitinol GI Stents",
      "LSO",
      "Optimed",
      "Stryker",
      "Taewoong",
      "Vascular Solutions",
    ],
    Tag: "interventional-endovascular",
  },
  {
    Index: 23,
    Description: "Therapeutic and Simulation",
    ProfilePicture: "therapeutic.jpg",
    WriteUp:
      "Our therapuetic and simulation division provides a wide range of medical equipment, first aid products and consumables with a focus on resuscitation, ventilation, emergency care, respiratory care, general practice, medical simulation and training. Leading supplier to acute and community hospitals, ambulance and emergency services, primary care centres and GP’s, community care and nursing, facilities, home health care, university medical and nursing schools and private businesses.",
    Businesses: [BUSINESSES.Cardiac],
    KeySuppliers: ["Laerdal"],
    Tag: "therapeutic-and-simulation",
  },
  {
    Index: 24,
    Description: "Interventional Neuroradiology",
    ProfilePicture: "int-neuro.jpg",
    WriteUp:
      "Our neuroradiology portfolio includes embolisation coils, flow diverters, embolic agents, neuro catheters, aneurysm devices and stentrievers.",
    Businesses: [
      BUSINESSES.Synapse,
      BUSINESSES.MacroMed,
      BUSINESSES.AngioCare,
      BUSINESSES.EPS,
      BUSINESSES.M3,
    ],
    KeySuppliers: [
      "Asahi",
      "Balt",
      "Emboflu",
      "Neuravi",
      "Microvention",
      "MIVI neuroscience",
      "Philips",
    ],
    Tag: "interventional-neuroradiology",
  },
  {
    Index: 25,
    Description: "Training and Education",
    ProfilePicture: "training.jpg",
    WriteUp:
      "Our Training and Education team offer consultancy, training, clinical education and change management for healthcare providers. We deliver installation training and support and provide a range of clinical education courses designed to develop a customer’s knowledge base and assist them in making the best use of the products and systems the company supply.",
    Businesses: [
      BUSINESSES.Cardiac,
      BUSINESSES.MED,
      BUSINESSES.Tekno,
      BUSINESSES.Synapse,
      BUSINESSES.MacroMed,
      BUSINESSES.AngioCare,
      BUSINESSES.EPS,
      BUSINESSES.M3,
    ],
    KeySuppliers: [],
    Tag: "training-and-education",
  },
  {
    Index: 26,
    Description: "Interventional Oncology",
    ProfilePicture: "int-oncology.jpg",
    WriteUp:
      "Our oncology portfolio offers products for interventional oncology, including radio-frequency ablation needles, drainage systems, bone marrow systems, introducer sets, histological and cytological biopsy equipment. ",
    Businesses: [BUSINESSES.Synapse, BUSINESSES.MacroMed, BUSINESSES.EPS],
    KeySuppliers: [
      "NeoLaser",
      "RF Medical",
      "HS Hospital Services",
      "Biocompatibles/BTG",
    ],
    Tag: "interventional-oncology",
  },
  {
    Index: 27,
    Description: "Urology and Female Pelvic Health",
    ProfilePicture: "urology.jpg",
    WriteUp:
      "Uniphar Medtech is one of the leading providers of Urology and Female Pelvic Health products representing global leading manufacturers. With over 25 years’ experience in this field, we offer a portfolio that spans urology, penile prosthesis, female and male incontinence, female pelvic health and urodynamics.",
    Businesses: [BUSINESSES.MED],
    KeySuppliers: ["Coloplast", "Lumenis", "Mediplus", "Telefex", "Urolift"],
    Tag: "urology-and-female-pelvic-health",
  },
  {
    Index: 28,
    Description: "Interventional Radiology",
    ProfilePicture: "int-radiology.jpg",
    WriteUp:
      "Our portfolio of interventional products spans both vascular and non-vascular. We offer CT GRID, histological biopsy, cytological biopsy, drainage systems, needle drainage introducer sets, stents, catheters, balloons and guidewires. ",
    Businesses: [
      BUSINESSES.Synapse,
      BUSINESSES.MacroMed,
      BUSINESSES.AngioCare,
      BUSINESSES.EPS,
      BUSINESSES.M3,
    ],
    KeySuppliers: [
      "ArraVacs",
      "Artventive",
      "Balt",
      "CardioNovum",
      "CID",
      "EKOS",
      "Introducer Sheaths",
      "Maquet",
      "Oscor",
      "Straub",
    ],
    Tag: "interventional-radiology",
  },
  {
    Index: 29,
    Description: "Vessel Closure",
    ProfilePicture: "vessel-closure.jpg",
    WriteUp:
      "Our portfolio covers the diagnosis of diseased blood vessels, angioplasties, stent placements and coronary thrombectomies and diagnostic/interventional cardiovascular procedures.",
    Businesses: [
      BUSINESSES.Synapse,
      BUSINESSES.MacroMed,
      BUSINESSES.AngioCare,
      BUSINESSES.EPS,
      BUSINESSES.M3,
    ],
    KeySuppliers: ["Asahi", "CeloNova", "OrbusNeich", "Shockwave"],
    Tag: "vessel-closure",
  },
];
