<div class="container-fluid home-menu-container  d-none d-lg-block">
    <div class="row">
        <div class="col-3">
            <a routerLink="/">
                <img [src]="'../../../assets/images/about/logo.svg'" class="home-logo" alt="Uniphar Medtech Logo" />
            </a>
        </div>
        <div class="col-9">
            <div class="home-menu">
                <ul>
                    <a>
                        <li class="title" [class.active-menu]="ActiveIndex === 0" (mouseover)="showSubMenu(subabout)" (mouseleave)="clearSubMenu(subabout)">
                            <a routerLink="/">Home</a>
                            <ul #subabout (mouseleave)="clearSubMenu(subabout)">
                                <a routerLink="/history"><li>History</li></a>
                                
                                <a routerLink="/vision"><li>Vision, Mission</li></a>
                                <a routerLink="/social"><li>Our Social Contribution</li></a>
                                <a routerLink="/culture"><li>Our Culture</li></a>
                            </ul>
                        </li>
                    </a>
                    <a (mouseover)="showSubMenu(subwhatwedo)" (mouseleave)="clearSubMenu(subwhatwedo)">
                        <li [class.active-menu]="ActiveIndex === 1">
                            <a routerLink="/what-we-do">What We Do</a>
                            <ul #subwhatwedo (mouseleave)="clearSubMenu(subwhatwedo)">
                                <a routerLink="/medical-device-distribution"><li>Medical Device Distribution</li></a>
                                <a routerLink="/specialities"><li>Specialities</li></a>
                                <a routerLink="/suppliers"><li>Suppliers</li></a>
                            </ul>
                        </li>
                    </a>
                    <a (mouseover)="showSubMenu(subwedomore)" (mouseleave)="clearSubMenu(subwedomore)">
                        <li>
                            <a routerLink="/we-do-more-intro">We Do More</a>
                            <ul #subwedomore (mouseleave)="clearSubMenu(subwedomore)">
                                <a routerLink="/value-chain"><li>Value Chain</li></a>
                                <a routerLink="/ourpeople"><li>Sales Structure</li></a>
                                <a routerLink="/we-do-more"><li>Support Divisions</li></a>
                            </ul>
                        </li>
                    </a>
                    <a (mouseover)="showSubMenu(subinvestors)" (mouseleave)="clearSubMenu(subinvestors)">
                        <li>
                            <a routerLink="/investors">Investors</a>
                            <ul #subinvestors (mouseleave)="clearSubMenu(subinvestors)">
                                <a routerLink="/investors"><li>Overview</li></a>
                                <a routerLink="/investors/acquisitions"><li>Acquisitions</li></a>
                            </ul>
                        </li>
                    </a>
                    <a (mouseover)="showSubMenu(subcareers)" (mouseleave)="clearSubMenu(subcareers)">
                        <li>
                            <a routerLink="/careers">Careers</a>
                            <ul #subcareers (mouseleave)="clearSubMenu(subcareers)">
                                <a routerLink="/careers/testimonials"><li>Testimonials</li></a>
                                <!--<a routerLink="/careers/positions"><li>Available Positions</li></a>-->
                                <a target="_blank" href="https://apply.workable.com/uniphar-medtech/?lng=en"><li>Available Positions</li></a>
                            </ul>
                        </li>
                    </a>
                    <a (mouseover)="showSubMenu(subourbusinesses)" (mouseleave)="clearSubMenu(subourbusinesses)">
                        <li>
                            <a routerLink="/ourbusinesses">Our Businesses</a>
                            <ul #subourbusinesses (mouseleave)="clearSubMenu(subourbusinesses)">
                                <a routerLink="/ourbusinesses"><li>Overview</li></a>
                            </ul>
                        </li>
                    </a>
                    <a (mouseover)="showSubMenu(subnews)" (mouseleave)="clearSubMenu(subnews)">
                        <li>
                            <a routerLink="/news">News</a> 
                            <ul #subnews (mouseleave)="clearSubMenu(subnews)">
                                <a routerLink="/news"><li>Latest Articles</li></a>
                            </ul>
                        </li>
                    </a>
                    <a>
                        <li>
                            <a routerLink="/contact">Contact</a>
                        </li>
                    </a>
                </ul>
            </div>
        </div>
    </div>
</div>
