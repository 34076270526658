<div class="padding-container">
  <div class="">
    <div class="row">
      <div class="col">
        <h1 class="suppliers">Suppliers</h1>
      </div>
    </div>
    


    <div class="row logo">
      <div class="col-6 col-md-3 col-lg-2" *ngFor="let item of contentsPagged">
        <img [src]="item.contentAlt" />
      </div>
    </div>

    <div class="row states">
      <div class="pager col">
        <ul>
          <li class="page"  *ngFor="let b of [].constructor(pages); let i = index">
            <div class="clickarea" (click)="SetStateId(i)">
              <div class="indicator" [class.active]="state===i"></div>
              </div>
          </li>
        </ul>
      </div>
    </div>

  </div>
</div>
<app-breadcrumb [Links]="'<a class=\'bold\'  href=\'\\what-we-do\'>What We Do</a>&nbsp;&nbsp;|&nbsp;&nbsp;Suppliers'"></app-breadcrumb>
