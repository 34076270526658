<div class="visioncontainer">
  <div class="top-img-container">
    <img [src]="vision1 || '../../../assets/images/Vision/implant.jpg'" />
    <div class="overlay"></div>
  </div>
  <a routerLink="/careers/testimonials">
    <div class="red">
      <div class="center-vertical tm-container ">
        <p class="tm">Read Our<br /><span>Testimonial&nbsp;&nbsp;</span></p>
      </div>
    </div>
  </a>
  <img class="bottom" [src]="vision2 || '../../../assets/images/Vision/scientist.jpg'" />
</div>
