<div class="padding-container">
    <div class="atf atf-init we-do-more-value-chain-ctr">
        <div class="row full-height">
            <div class="col-12 col-md-5 col-lg-4 full-height left" id="left">
                <h1>{{pageMainComponent?.sectionTitle}}</h1>
                <p [innerHTML]="pageMainComponent?.sectionContent">
                </p>
                <a routerLink="/ourpeople">
                    <ul class="attention">

                        <li>
                            <app-attention-bar></app-attention-bar>
                        </li>
                        <li>
                            <p class="nowrap">Sales Structure</p>
                        </li>
                    </ul>
                </a>
            </div>
            <div class="col-12 col-md-7  col-lg-8 full-height" id="right">
                <div class="container full-height">
                    <div class="row full-height">
                        <div class="col full-height" style="padding-left: 0px;">
                            <div class="videoContainer">
                                <div class="videoWrapper">
                                    <iframe width="560" height="315" [src]="safeSrc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-breadcrumb
    [Links]="'<a class=\'bold\'  href=\'\\we-do-more-intro\'>We Do More</a>&nbsp;&nbsp;|&nbsp;&nbsp;Value Chain'">
</app-breadcrumb>