import { Component, OnInit, Input } from '@angular/core';
import { faHome } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.less']
})
export class BreadcrumbComponent implements OnInit {
  @Input() Links:string="";
  faHome = faHome;

  constructor() { }

  ngOnInit(): void {
  }

}
