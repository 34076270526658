import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-percentage-block-horizontal',
  templateUrl: './percentage-block-horizontal.component.html',
  styleUrls: ['./percentage-block-horizontal.component.less']
})
export class PercentageBlockHorizontalComponent implements OnInit {
  @Input() TitleText:string="";
  @Input() TitleTextFontSize:number = 30;
  @Input() BodyText:string="";
  @Input() BodyTextFontSize:number = 22;
  constructor() { }

  ngOnInit(): void {
  }

  getTitleText(): string {
    return this.TitleText.replace("*", "<span class='highlight'>").replace("*", "</span>");;
  }

  getBodyText(): string {
    return this.BodyText.replace("*", "<span class='highlight'>").replace("*", "</span>");
  }

}
