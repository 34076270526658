<div class="padding-container">
  <div class="row">
    <div class="col-12 col-md-7 col-xl-6  order-1 order-md-0">
      <div class="article-ctr">
        <div class="pager">
          <ul>
            <li class="page"  *ngFor="let b of ArticlePreviews; let i = index">
              <div class="clickarea" (click)="SetStateId(i)">
                <div class="indicator" [class.active]="state===i"></div>
              </div>
            </li>
          </ul>
        </div>
        <div class="article">
          <h1>{{CurrentArticlePreview?.blogTitle}}</h1>
          <p class="company">{{CurrentArticlePreview?.blogSubTitle.toUpperCase()}}</p>
          <div class="row">
            <div class="col-6 d-none d-lg-block">
              <p [innerHTML]="CurrentArticlePreview?.previewColumn1"></p>
            </div>
            <div class="col-6 d-none d-lg-block">
              <p [innerHTML]="CurrentArticlePreview?.previewColumn2"></p>
            </div>
            <div class="col-12 d-lg-none">
              <p [innerHTML]="CurrentArticlePreview?.previewColumn1 + ' ' + CurrentArticlePreview?.previewColumn2"></p>
            </div>
          </div>
          <div class="row">
            <div class="col top-space">
              <app-arrow-button [ButtonText]="'Read Full Article'" (onClicked)="onView(CurrentArticlePreview)"></app-arrow-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-5 col-xl-6 order-0 order-md-1">
      <div class="preview-ctr"
        [style.backgroundImage]="'url('+ CurrentArticlePreview?.blogAlt +')'">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="headlines">
        <h2>News Articles</h2>
        <div class="divider"></div>
        <div class="headline-item" *ngFor="let h of Headlines">
          <div class="thumbnail" [style.backgroundImage]="'url('+ h?.blogAlt +')'">
          </div>
          <h3>{{h.blogTitle}}</h3>
          <p class="date">{{h.blogDateString}}</p>
          <p>{{h.seoWriteUp}}...</p>
          <app-arrow-button [ButtonText]="'Read More'" [TextColor]="'#666'" [FontSize]="12" (onClicked)="onView(h)"></app-arrow-button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-breadcrumb [Links]="'<a class=\'bold\'  href=\'\\news\'>News</a>&nbsp;&nbsp;|&nbsp;&nbsp;Latest Articles'">
</app-breadcrumb>