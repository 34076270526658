import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Page, PageComponent } from 'src/app/models/page';
import { PageService } from 'src/app/service/page.service';

@Component({
  selector: 'app-we-do-more-intro',
  templateUrl: './we-do-more-intro.component.html',
  styleUrls: ['./we-do-more-intro.component.less']
})
export class WeDoMoreIntroComponent implements OnInit {

  pageCode: string = "WDMP001";
  pageResource: Page;
  pageMainComponent: PageComponent;

  constructor(private _service: PageService, private titleService: Title) {
    this.titleService.setTitle("We Do More | Uniphar Medtech");
   }

  ngOnInit(): void {
    this.loadReferenceData();
  }

  loadReferenceData(){
    this._service.getPageByCode(this.pageCode).subscribe(result => {
      if(result)
      {
        this.pageResource = result as Page;
        this.pageMainComponent = result.pageComponents.find(x => x.id == 40);
        //console.log(this.pageResource);
      }
    });

  }
}
