<!--<div class="ctr">
  <div class="image"
    [ngStyle]="{'background-image':'url('+ getProfilePicture() +')'}">
    <div class="copy">
      <p class="title">{{Speciality?.description}}</p>
      <p class="body">{{ (Speciality?.writeUp.length > 210) ? (Speciality?.writeUp | slice:0:209)+'...' : Speciality?.writeUp  }}</p>
    </div>
  </div>
</div>-->
<div class="speciality-ctr">
    <img [src]="(Speciality) ? '../../../../assets/images/what-we-do/specialities/new/'+ Speciality.description + '.png' : '../../../../assets/images/what-we-do/specialities/new/Ambulatory Aids.png'" />
    <div class="content-box">
        <h2>{{Speciality?.description || 'Ambulatory Aids'}}</h2>
        <p>
            {{ ((Speciality?.writeUp.length > 250) ? (Speciality?.writeUp | slice:0:249)+'...' : Speciality?.writeUp) || 'Uniphar Medtech is partnered with the world’s leading manufacturers including the Sunrise Medical (Coopers), The Helping Hand Company and Herdegen. Our portfolio includes crutches, walking frames, sticks and extends to aids for daily living such as ...'  }}
        </p>
    </div>
</div>
