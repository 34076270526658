export class ArticlePreview {
  title:string;
  company:string;
  column1:string;
  column2:string;
  url:string;
  image:string;
}

export const ARTICLES:ArticlePreview[] = [
  {
    title: "Q4 Meetings & Exhibitions",
    company: "Synapse Medical",
    column1: `
    <p>Synapse Medical have had a busy year to date within the Diagnostic Imaging division. Here is a snapshot of what we have attended this year in DI and what we have coming up:</p>
    <ul>
    <li>UKRC Liverpool</li>
    <li>South England MRI User Group Meeting in Bournemouth</li>
    <li>CT Study day in London’s British Medical Association House</li>
    <li>Medical imaging Convention in London</li>
    <li>Northern Annual Scientific Meeting in Leeds</li>
    <li>European Society of Gastrointestinal and Abdominal Radiology; Dublin</li>
    </ul>
    `,
    column2: `
    <p>Upcoming Educational days and conventions:</p>
    <ul>
    <li>CT Study Day Glasgow Royal College of Surgeons</li>
    <li>MRI and CT study day Queen Elizabeth Birmingham,</li>
    <li>Irish Nuclear Medicine Association and British Nuclear Medicine Association Joint Meeting in Dublin</li>
    <li>DaTscan Reporting Workshop in Dublin</li>
    <li>Radiology Society of North America, Chicago IL, Annual Meeting 2018, 25th – 30th November 2018, McCormick Place, CHICAGO, IL, USA</li>
    <li>Medica Dusseldorf - Nov 12, 2018 - Nov 15, 2018</li>
    </ul>
    `,
    url: "https://a",
    image: "preview-1.jpg",
  },
  {
    title: "M.E.D Surgical Launches UV-C Disinfection Robot.",
    company: "M.E.D Surgical",
    column1: "M.E.D. Surgical, a medical device distributor with 20 years’ experience in decontamination solutions and technologies, has today announced the successful launch of decontamination technology into FirstCare Nursing Homes. The contactless, ultraviolet light (UV-C) robot is clinically proven to sterilise and disinfect all hard surfaces, eliminating viruses, bacteria and pollutants up to 6 meters in each direction.",
    column2: "Mary Llyod, Director of Admissions at FirstCare highlights the reasons for choosing Steripro; ‘Steripro helps provide reassurance for all residents and families that are coming into our nursing homes. It is important for them to know that the building has been decontaminated and it’s as clean as we can possibly get it to minimise and reduce the risk of infection’.",
    url: "https://b",
    image: "preview-2.jpg",
  },
  {
    title: "Exeter Hip Symposium",
    company: "Tekno Surgical",
    column1: "Tekno Surgical held its annual Exeter Hip Symposium to celebrate 50 years of Stryker's Exeter Hip. It was an extremely informative day filled with very interesting talks on current issues and new innovations surrounding Orthopaedic surgery. The topics of discussion were extremely diverse ranging from the use of Mako Robotic Technology, various reconstruction techniques and much more.",
    column2: "A massive thank you to the Royal College of Surgeons in Ireland and Amanda Wilkinson for their fantastic venue and support on the day. A special thank you to our faculty for their insightful talks and discussions at this event.",
    url: "https://c",
    image: "preview-3.jpg",
  },
  {
    title: "Queen Alexandra Hospital go with Ulrich MAX 3",
    company: "Synapse Medical",
    column1: "Synapse Medical UK are delighted to announce that the MRI department at Queen Alexandra Hospital in Portsmouth have selected Ulrich’s new MAX 3 contrast injector to meet the immediate and future injector needs in their department.",
    column2: "The new MAX injector aims to deliver unparalleled efficiencies to the new MRI department within Queen Alexandra. The Diagnostic Imaging team for Synapse Medical received excellent feedback from the hospital following the injector installation.",
    url: "https://d",
    image: "preview-4.jpg",
  }
];


export class Headline {
  title: string;
  publishdate: string;
  description: string;
  url: string;
  company:string;
  image: string;
  index:number;
}

export const HEADLINES:Headline[] = [
  {
    company: "Synapse Medical",
    description: "Synapse Medical have had a busy year to date within the Diagnostic Imaging division. Here is a snapshot of what we have attended this year in DI and what we have coming up",
    publishdate: "September 2020",
    title: "Q4 Meetings & Exhibitions",
    url: "",
    image: "1.jpg",
    index: 0,
  },
  {
    company: "Synapse Medical",
    description: "Synapse Medical UK are delighted to announce that the MRI department at Queen Alexandra Hospital in Portsmouth have selected Ulrich’s",
    publishdate: "July 2020",
    title: "Queen Alexandra Hospital go with Ulrich MAX 3",
    url: "",
    image: "2.jpg",
    index: 1,
  },
  {
    company: "Synapse Medical",
    description: "CHALLENGE IN THE HOSPITAL ENVIRONMENT With the increasing awareness of CIN and radiation dose, Queen Elizabeth Hospital Birmingham (QEHB) identified that trends",
    publishdate: "May 2020",
    title: "UHB Saves 8% A Year On Injector Consumables",
    url: "",
    image: "3.jpg",
    index: 2,
  },
  {
    company: "Synapse Medical",
    description: "PUTTING PATIENTS AT THE HEART OF EVERYTHING WE DO Kingston Hospital NHS Foundation Trust recently decided to replace their existing Ulrich",
    publishdate: "April 2020",
    title: "Kingston Hospital Upgrades To Ulrich CT Motion Contrast Injectors",
    url: "",
    image: "4.jpg",
    index: 3,
  },
  {
    company: "Synapse Medical",
    description: "SISK Healthcare are delighted to announce the launch of their new websites for TEKNO Surgical, M.E.D. Surgical and Synapse Medical",
    publishdate: "January 2020",
    title: "SISK Healthcare Launch New Websites",
    url: "",
    image: "5.jpg",
    index: 4,
  },
];
