<div class="ctr">
  <div id="copy" class="copy hidden">
    <img [src]="pageTopAsset?.filePath" />
  </div>
  <div class="arrow">
    <img src="../../../../assets/images/grey-left.png" />
  </div>
  
  <div id="slide" class="slide open" (mouseover)="hideImage()" (mouseout)="showImage()"  >
    <div class="overlay">
    </div>
    <div [innerHTML]="pageComponent?.statContent"> </div>

  </div>
</div>
