<div class="padding-container">
     <div class="atf atf-init">
          <div class="row full-height">
               <div class="col-12 col-md map">
                    <div class="gradient">

                    </div>
               </div>
               <div class="col-12 col-md padding">

                    <h1>Contact Us</h1>
                   
                    <div *ngIf="!isSubmitted">
                         <form [formGroup]="contactForm" (ngSubmit)="onSubmit(contactForm.value)" autocomplete="off">
                              <input type="hidden" autocomplete="false">
                              <input type="text" id="_fullname" placeholder="Full name"
                                   [formControl]="contactForm.controls['fullname']">
                              <div class="_hidden" [class._show]="fullname.valid">
                                   <input type="email" id="_email" placeholder="Email address" (keyup)="OnEmailKeyUp()"
                                        [formControl]="contactForm.controls['email']">
                              </div>
                              <div class="contact _hidden" [class._show]="fullname.valid && email.valid">
                                   <input type="text" id="countrycode" placeholder="Country Code"
                                        [formControl]="contactForm.controls['countrycode']">
                                   <input type="text" id="contactnumber" placeholder="Contact Number"
                                        [formControl]="contactForm.controls['contactnumber']">
                                   <div style="clear: both"></div>
                              </div>
                              <div style="clear: both"></div>
                              <div class="interest _hidden"
                                   [class._show]="fullname.valid && email.valid && contactnumber.valid">
                                   <ul class="radio-options divide">
                                        <li><strong>Interest?</strong></li>
                                        <li><input type="radio" id="supplier" name="interest" value="supplier"
                                                  [formControl]="contactForm.controls['interest']"> Supplier</li>
                                        <li><input type="radio" id="investor" name="interest" value="investor"
                                                  [formControl]="contactForm.controls['interest']"> Investor</li>
                                        <li><input type="radio" id="recruiter" name="interest" value="recruiter"
                                                  [formControl]="contactForm.controls['interest']"> Recruiter</li>
                                        <li><input type="radio" id="other" name="interest" value="other"
                                                  [formControl]="contactForm.controls['interest']"> Other</li>
                                   </ul>
                              </div>
                              <div class="contact _hidden"
                                   [class._show]="fullname.valid && email.valid && contactnumber.valid && interest.valid">
                                   <ul class="radio-options">
                                        <li><strong>How can we contact you?</strong></li>
                                        <li><input type="radio" id="call" name="contact_you" value="call"
                                                  [formControl]="contactForm.controls['contact_you']"> Call</li>
                                        <li><input type="radio" id="email" name="contact_you" value="email"
                                                  [formControl]="contactForm.controls['contact_you']"> Email</li>
                                   </ul>
                              </div>
                              <div class="message _hidden"
                                   [class._show]="fullname.valid && email.valid && contactnumber.valid && interest.valid && contact_you.valid">
                                   <textarea placeholder="Your message" [formControl]="contactForm.controls['message']"></textarea>
                              </div>
                              <br />
                              <div *ngIf="formHasErrors">
                                   <p style="color: red;">Please ensure all fields are filled in before submitting.</p>
                              </div>
                              <button type="submit"
                                   [class._show]="fullname.valid && email.valid && contactnumber.valid && interest.valid && contact_you.valid">
                                   <app-arrow-button [ButtonText]="'Submit'"></app-arrow-button>
                              </button>
                              <br />
                              <div class="form-progress-bar">
                                   <div class="line"></div>
                                   <ul>
                                        <li>
                                             <div class="node" [class.active]="fullname.valid"></div>
                                        </li>
                                        <li>
                                             <div class="node" [class.active]="fullname.valid && email.valid"></div>
                                        </li>
                                        <li>
                                             <div class="node"
                                                  [class.active]="fullname.valid && email.valid && contactnumber.valid">
                                             </div>
                                        </li>
                                        <li>
                                             <div class="node"
                                                  [class.active]="fullname.valid && email.valid && contactnumber.valid && interest.valid">
                                             </div>
                                        </li>
                                        <li>
                                             <div class="node"
                                                  [class.active]="fullname.valid && email.valid && contactnumber.valid && interest.valid && contact_you.valid">
                                             </div>
                                        </li>
                                   </ul>
                              </div>
                         </form>
                    </div>
                    <div *ngIf="isSubmitted">
                         <p class="success-text">Thank you, we have received your enquiry, and one of our
                              representatives will be in touch with you shortly.</p>
                    </div>
                    

               </div>
          </div>
     </div>
</div>
<app-breadcrumb
     [Links]="'<a class=\'bold\'  href=\'\\contact\'>Contact</a>&nbsp;&nbsp;|&nbsp;&nbsp;Contact Us&nbsp;&nbsp;'">
</app-breadcrumb>