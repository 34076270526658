import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { PageComponent } from 'src/app/models/page';

@Component({
  selector: 'app-social-slides',
  templateUrl: './social-slides.component.html',
  styleUrls: ['./social-slides.component.less']
})
export class SocialSlidesComponent implements OnInit {
  
  @Input() pageComponent: PageComponent[];
  @Input() state:number = 0;
  constructor() { }

  ngOnInit(): void {
    this.loadImages();
  }

  ngOnChanges()
  {
    this.loadImages();
  }

  
  loadImages()
  {
    if(this.pageComponent)
    {
      this.pageComponent.forEach(element => {
        if(element && element.id == 19)
        {
          var s1 = element.pageComponentAssets.find(x => x.altAttribute == "social-1");
          var s02 = element.pageComponentAssets.find(x => x.altAttribute == "social-2");

          if(s1)
          {
            document.documentElement.style.setProperty('--social-bg-s1-url',`url("${s1.filePath}") no-repeat center center`);
          }

          if(s02)
          {
            document.documentElement.style.setProperty('--social-bg-s02-url',`url("${s02.filePath}") no-repeat center center`);;
          }
        }
        else if(element && element.id == 20)
        {
          var s03 = element.pageComponentAssets.find(x => x.altAttribute == "social-3");
          var s2 = element.pageComponentAssets.find(x => x.altAttribute == "social-4");

          if(s2)
          {
            document.documentElement.style.setProperty('--social-bg-s2-url',`url("${s2.filePath}") no-repeat center center`);
          }

          if(s03)
          {
            document.documentElement.style.setProperty('--social-bg-s03-url',`url("${s03.filePath}") no-repeat center center`);;
          }

        }
        else if(element && element.id == 21)
        {
          var s04 = element.pageComponentAssets.find(x => x.altAttribute == "social-5");
          var s3 = element.pageComponentAssets.find(x => x.altAttribute == "social-6");

          if(s3)
          {
            document.documentElement.style.setProperty('--social-bg-s3-url',`url("${s3.filePath}") no-repeat center center`);
          }

          if(s04)
          {
            document.documentElement.style.setProperty('--social-bg-s04-url',`url("${s04.filePath}") no-repeat center center`);;
          }

        }
        else if(element && element.id == 22)
        {
          var bw = element.pageComponentAssets.find(x => x.altAttribute == "social-7");
          var bc = element.pageComponentAssets.find(x => x.altAttribute == "social-8");
          var tc = element.pageComponentAssets.find(x => x.altAttribute == "social-9");

          if(bw)
          {
            document.documentElement.style.setProperty('--social-bg-bw-url',`url("${bw.filePath}") no-repeat center center`);
          }

          if(bc)
          {
            document.documentElement.style.setProperty('--social-bg-bc-url',`url("${bc.filePath}") no-repeat center center`);;
          }

          if(tc)
          {
            document.documentElement.style.setProperty('--social-bg-tc-url',`url("${tc.filePath}") no-repeat center center`);;
          }

        }
        else if(element && element.id == 23)
        {
          var s5 = element.pageComponentAssets.find(x => x.altAttribute == "social-10");

          if(s5)
          {
            document.documentElement.style.setProperty('--social-bg-s5-url',`url("${s5.filePath}") no-repeat center center`);
          }


        }
      });

    }

  }



}
