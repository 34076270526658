<div id="ctr" (resized)="onResized($event)">
  <h1>Our Social Contribution</h1>
  <app-pager [state]="state" (onStateChanged)="SetState($event)"></app-pager>
  <app-copy-ticker-mobile [state]="state" 
  [social1]="social1"
  [social2]="social2"
  [social3]="social3"
  [social4]="social4"
  [social5]="social5"></app-copy-ticker-mobile>
  <div class="slides">
    <div class="center-img state-{{state}}">
      <div class="red state-{{state}}"></div>
      <div class="floating-image state-{{state}}"></div>
      <div class="bottom state-{{state}}"></div>
      <div class="top state-{{state}}"></div>
    </div>

  </div>
</div>
