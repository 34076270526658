<div class="padding-container">
  <h1>{{selectedContent?.blogTitle}}</h1>
  <p><strong>{{selectedContent?.blogSubTitle.toUpperCase()}}</strong><br />
    <i>{{selectedContent?.blogDateString.toUpperCase()}}</i>
    <strong>{{selectedContent?.blogAuthor.toUpperCase()}}</strong>
  </p>
  <br />
  <div class="image-container" *ngIf="selectedContent?.blogImage">
      <img [src]="selectedContent?.blogImage" />
  </div>

  <br />

  <div class="writeup-container" *ngIf="selectedContent" [innerHTML]="writeUp"></div>

  <br /><br /><br /><br /><br /><br /><br /><br /><br />
  <br /><br /><br /><br /><br /><br /><br /><br /><br />
</div>
<app-breadcrumb [Links]="'<a class=\'bold\'  href=\'\\news\'>News</a>&nbsp;&nbsp;|&nbsp;&nbsp;Article'">
</app-breadcrumb>
