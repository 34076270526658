<div *ngIf="Target.length>0 && !LinkExternal">
    <a routerLink="{{Target}}">
        <ul class="arrow-container">
            <li>
                <p [style.color]="TextColor" [style.fontSize]="FontSize">{{ButtonText}}</p>
            </li>
            <li>
                <svg id="arrow" width="30" height="20" style="margin-left: 25px;">
                    <line x1="0" y1="10" x2="30" y2="10" style="stroke-width: 1" [style.stroke]="TextColor" />;
                    <line id="top-arrow" x1="22" y1="10" x2="30" y2="10" style="stroke-width: 1; "
                        [style.stroke]="TextColor" />;
                    <line id="bottom-arrow" x1="22" y1="10" x2="30" y2="10" style="stroke-width: 1; "
                        [style.stroke]="TextColor" />;
                </svg>
            </li>
        </ul>
    </a>
</div>

<div *ngIf="Target.length>0 && LinkExternal">
    <a href="{{Target}}" target="_blank">
        <ul class="arrow-container">
            <li>
                <p [style.color]="TextColor" [style.fontSize]="FontSize">{{ButtonText}}</p>
            </li>
            <li>
                <svg id="arrow" width="30" height="20" style="margin-left: 25px;">
                    <line x1="0" y1="10" x2="30" y2="10" style="stroke-width: 1" [style.stroke]="TextColor" />;
                    <line id="top-arrow" x1="22" y1="10" x2="30" y2="10" style="stroke-width: 1; "
                        [style.stroke]="TextColor" />;
                    <line id="bottom-arrow" x1="22" y1="10" x2="30" y2="10" style="stroke-width: 1; "
                        [style.stroke]="TextColor" />;
                </svg>
            </li>
        </ul>
    </a>
</div>

<div *ngIf="Target.length==0">
    <ul class="arrow-container" (click)="ButtonClicked()">
        <li>
            <p [style.color]="TextColor" [style.fontSize]="FontSize">{{ButtonText}}</p>
        </li>
        <li>
            <svg id="arrow" width="30" height="20" style="margin-left: 25px;">
                <line x1="0" y1="10" x2="30" y2="10" style="stroke-width: 1" [style.stroke]="TextColor" />;
                <line id="top-arrow" x1="22" y1="10" x2="30" y2="10" style="stroke-width: 1; "
                    [style.stroke]="TextColor" />;
                <line id="bottom-arrow" x1="22" y1="10" x2="30" y2="10" style="stroke-width: 1; "
                    [style.stroke]="TextColor" />;
            </svg>
        </li>
    </ul>
</div>