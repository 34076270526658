import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Page } from './models/page';
import { PageService } from './service/page.service';
import { CookieService } from './services/cookie.service';
import { Router, NavigationEnd } from '@angular/router';


declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent  implements OnInit, OnDestroy {
  isMenuOpen:boolean = false;
  isMobiMenuOpen:boolean = false;
  is_GA_Allowed:boolean = false;
  hasUserSetPreferences:boolean = false;
  pageCode: string = "TNC001";
  cookiePDF: string = "https://api.siskhealthcare.com/images/7aa4520c-e15f-4fc1-b62a-f83c188ee697_Cookie05.01.21.pdf";

  title = 'mainsite';

  

  /**
   *
   */
  constructor(private cookieService:CookieService, private _service: PageService,  public router: Router) {
    if (this.cookieService.getValue("GA") === "yes") {
      this.is_GA_Allowed = true;
    }
    this.hasUserSetPreferences = this.cookieService.hasUserSetPreferences();

    this._service.getPageByCode(this.pageCode).subscribe(result => {
      if(result)
      {
        var pageResource = result.pageComponents.find(x => x.id == 42);

        if(pageResource && pageResource.pageComponentAssets && pageResource.pageComponentAssets.length > 0)
        {
          this.cookiePDF = pageResource.pageComponentAssets[0].filePath; 
        }

      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log("GTAG: " + event.urlAfterRedirects);
        gtag('config', 'G-QX97YKP41E',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    });
  }

  acceptAll() {
    this.cookieService.acceptAllCookies();
    this.is_GA_Allowed = true;
    this.hideCookieBanner();
    this.hidePopup();
  }

  rejectAll() {
    this.cookieService.rejectAllCookies();
    this.is_GA_Allowed = false;
    this.hideCookieBanner();
    this.hidePopup();
  }
  changeGA(e) {
    let change:boolean = e.target.checked;
    this.is_GA_Allowed = change;
    if (change) {
      this.cookieService.setValue("GA", "yes");
    } else {
      this.cookieService.setValue("GA", "no");
    }
  }


  ToggleClass() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  ToggleClassMobi() {
    this.isMobiMenuOpen = !this.isMobiMenuOpen;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  hidePopup() {
    document.getElementById("cookiepopup").style.display = "none";
  }

  hideCookieBanner() {
    document.getElementById("cookiebanner").style.display = "none";
  }

  showPopup() {
    document.getElementById("cookiepopup").style.display = "block";
  }


  showCookieBanner() {
    document.getElementById("cookiebanner").style.display = "block";
  }
}

