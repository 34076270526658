<div id="image-reveal-container">
    <div class="left-img">
        <img [src]="getAltAttribute('about-1') || '../../../assets/images/about/about-1.jpg'"
            [alt]="getAltTag('about-1') || 'about-1'" />
    </div>
    <div class="right-img">
        <img [src]="getAltAttribute('about-2') || '../../../assets/images/about/about-2.jpg'"
            [alt]="getAltTag('about-2') || 'about-2'" />
    </div>
    <div style="clear:both"></div>
    <div class="border"></div>
    <div class="logo">
        <img [src]="getAltAttribute('logo') || '../../../assets/images/about/logo.png'"
            [alt]="getAltTag('logo') || 'logo'" />
    </div>
    <div style="clear:both"></div>
</div>
<div style="clear:both"></div>