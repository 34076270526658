import { Component, OnInit, Input } from '@angular/core';
import { faHome } from "@fortawesome/free-solid-svg-icons";


@Component({
  selector: 'app-inline-breadcrumb',
  templateUrl: './inline-breadcrumb.component.html',
  styleUrls: ['./inline-breadcrumb.component.less']
})
export class InlineBreadcrumbComponent implements OnInit {
  faHome = faHome;
  @Input() Links:string="";
  constructor() { }

  ngOnInit(): void {
  }

}
