import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.less']
})
export class PagerComponent implements OnInit {
  @Input() state:number = 0;
  @Output() onStateChanged:EventEmitter<number>;

  constructor() {
    this.onStateChanged = new EventEmitter<number>();
  }

  ngOnInit(): void {
  }

  SetState(state:number) {
    this.onStateChanged.emit(state);
  }
}
