import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Source } from 'webpack-sources';
import { environment } from 'src/environments/environment';
import { WebContact } from '../models/webcontact';

const httpOptions = {
     headers: new HttpHeaders({
          'Content-Type': 'application/json'
     })
};

@Injectable({
     providedIn: 'root'
})
export class ContactService {
     private _url: string = environment.apiUrl;
     private serviceUrl: string = this._url + "/api/v1/cms/contact-us";

     constructor(private http: HttpClient) { }

     submitContactEnquiry(webContact: WebContact): Observable<any> {
          //console.log(webContact);
          //console.log(JSON.stringify(webContact));
          return this.http.post<any>(this.serviceUrl, JSON.stringify(webContact), httpOptions);
     }
}
