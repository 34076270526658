import { Component, OnInit, Input } from '@angular/core';
import { PageComponent } from 'src/app/models/page';

@Component({
  selector: 'app-our-mission-mobile',
  templateUrl: './our-mission-mobile.component.html',
  styleUrls: ['./our-mission-mobile.component.less']
})
export class OurMissionMobileComponent implements OnInit {
  @Input() pageComponent: PageComponent;
  mission1: string;
  mission2: string;
  mission3: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if(this.pageComponent)
    {
        this.pageComponent.pageComponentAssets.forEach(element => {
          if(element.seoFileName == 'mission-1')
          {
            this.mission1 = element.filePath;
          }
          else if(element.seoFileName == 'mission-2')
          {
            this.mission2 = element.filePath;
          }
          else if(element.seoFileName == 'mission-3')
          {
            this.mission3 = element.filePath;
          }
          
        });

    }
  }

}
