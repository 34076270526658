<div class="padding-container desktop">
  <div class="atf atf-init">
    <div class="row full-height">
      <div class="col-6 full-height">
        <h1 class="vision">Our Vision</h1>
        <p class="ourvisioncopy" *ngIf="!pageComponentVision">
          Through innovation, ingenuity and dedication to our customers,
          our vision is to be a diversified healthcare company
          focused on providing best-in-class products
          and services which improve patient care and enhance
          the quality of people's lives.
        </p>
        <p class="ourvisioncopy" *ngIf="pageComponentVision" [innerHTML]="pageComponentVision.sectionContent">

        </p>
        <div class="mission" (click)="gotoMission(mission)">
          <p>Mission</p>
        </div>
      </div>
      <div class="col-6 full-height">
        <div class="ourvision">
          <app-our-vision [pageComponent]="pageComponentVision"></app-our-vision>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid atf atf-init">
    <div class="row full-height">
      <div class="col-6 full-height" #mission>
        <h1 class="vision">Our Mission</h1>
        <p class="ourvisioncopy" *ngIf="!pageComponentMisson">
          As a medical device distributor, our mission is lived everyday by providing our clients
          with the highest quality products to improve patient care across Europe. We aim to provide best-in-class
          training and education, utilising the latest innovative technologies whilst supporting our customers
          throughout
          the patient life-cycle. With experienced sales representatives, on-site engineers and customer service agents,
          we pride ourselves on service excellence.
        </p>
        <p class="ourvisioncopy" *ngIf="pageComponentMisson" [innerHTML]="pageComponentMisson.sectionContent">
        </p>
        <div class="vision" (click)="gotoVision()">
          <p>Vision</p>
        </div>
      </div>
      <div class="col-6 full-height">
        <div class="ourmission">
          <app-our-mission [pageComponent]="pageComponentMisson"></app-our-mission>
        </div>
      </div>
    </div>
  </div>
  <div class="next-step">
    <a routerLink="/social">
      <ul class="attention">
        <li>
          <app-attention-bar></app-attention-bar>
        </li>
        <li><p>Our Social Contribution</p></li>
      </ul>
    </a>
  </div>
</div>

<div class="mobile">
  <app-our-vision-mobile [pageComponent]="pageComponentVision"></app-our-vision-mobile>
  <app-our-mission-mobile [pageComponent]="pageComponentMisson"></app-our-mission-mobile>
  <div class="next-step">
    <a routerLink="/social">
      <ul class="attention">
        <li>
          <app-attention-bar></app-attention-bar>
        </li>
        <li><p>Our Social<br /> Contribution</p></li>
      </ul>
    </a>
    <div style="clear:both"></div>
  </div>
  <div style="clear:both"></div>
</div>
<div style="clear:both"></div>
<app-breadcrumb [Links]="'<a class=\'bold\'  href=\'\\\'>Home</a>&nbsp;&nbsp;|&nbsp;&nbsp;Vision & Mission'">
</app-breadcrumb>
<div style="clear:both"></div>
